import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import 'remixicon/fonts/remixicon.css'
import Vuelidate from 'vuelidate'
import VModal from 'vue-js-modal'
import loader from 'vue-ui-preloader'

Vue.use(Vuelidate)
Vue.use(VueMeta)
Vue.use(VModal)
Vue.use(loader)

import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCvzMfJSEyJftppngNmZRmreD-JDg4uXfY',
    libraries: 'places',
    region: 'ca,en',
    installComponents: true
  }
})

Vue.prototype.$getID = () => {
  return +Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
    .toString()
    .substring(0, 6)
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
