<template>
  <div class="admin-layout">
    <TheHeader />
    <router-view />
  </div>
</template>

<script>
import TheHeader from '@/views/Admin/components/TheHeader'
import {mapState, mapActions} from 'vuex'

export default {
  name: 'AdminLayout',
  components: {TheHeader},
  computed: {
    ...mapState({
      user: s => s.auth.user
    })
  },
  created() {
    if (!this.user.uid) this.getUser()
    this.getConstants()
  },
  methods: {
    ...mapActions({
      getUser: 'auth/setUser',
      getConstants: 'main/fetchConstants'
    })
  }
}
</script>
