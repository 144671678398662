<template>
  <header class="header">
    <div class="container">
      <div class="header__logo">
        <router-link to="/">
          <img src="@/assets/icons/logo-new-color.svg" alt="Long Lane Welding Suppliy" class="header__img" />
        </router-link>
      </div>
      <Menu />
      <div class="nav-menu__btn"><router-link to="/contacts" class="btn btn--small">Get in Touch</router-link></div>
    </div>
  </header>
</template>
<script>
import Menu from '../Menu'
export default {
  name: 'Home',
  components: {
    Menu
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
