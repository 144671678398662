<template>
  <header class="header">
    <div :class="['overlay', overlay ? 'overlayActive' : '']" @click="overlayClick"></div>
    <div class="container">
      <div id="showblock" class="line-wrap" @click="toggle">
        <div class="" :class="['line', isClicked ? 'lineClicked' : '']"></div>
      </div>
      <div class="header__logo">
        <router-link to="/" @click.native="toggle">
          <img src="@/assets/icons/logo.svg" alt="Long Lane Welding Suppliy" class="header__img" />
        </router-link>
      </div>

      <div class="nav-menu__btn" :class="{clicked: isClicked}">
        <router-link to="/admin/products" class="header__link" @click.native="toggle">Products</router-link>
        <router-link to="/admin/categoryEdit" class="header__link" @click.native="toggle">Categories</router-link>
        <label for="search" class="header__label">
          <input
            id="search"
            :value="searchInput"
            type="text"
            class="header__input"
            placeholder="Search ID or Name"
            @input="search"
          />
        </label>
        <div class="btn-wrap">
          <router-link to="/admin/product" class="btn btn--small" @click.native="toggle">
            <span class="btn--small__icon"><i class="ri-add-line"></i></span> Add Product
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import {mapActions, mapState, mapMutations} from 'vuex'
import _ from 'lodash'

export default {
  name: 'Home',
  data() {
    return {
      isClicked: false,
      overlay: false,
      searchInput: null
    }
  },
  computed: {
    ...mapState({
      products: s => s.admin.products.products,
      stateFilterCat: s => s.admin.products.filterCat,
      stateSearch: s => s.admin.products.searchName
    })
  },
  watch: {
    stateFilterCat(cat) {
      if (cat) this.searchInput = null
    },
    $route() {
      this.searchInput = null
    },
    stateSearch(name) {
      if (name === null) {
        this.searchInput = null
      }
    }
  },
  async created() {
    await this.getProducts()
  },
  methods: {
    ...mapActions({
      getProducts: 'admin/products/fetch'
    }),
    ...mapMutations({
      changeProducts: 'admin/products/changeProducts',
      setSearchName: 'admin/products/setSearchName',
      setFilterCat: 'admin/products/setFilterCat',
      setFilterSubCat: 'admin/products/setFilterSubCat',
      setFilterSubSubCat: 'admin/products/setFilterSubSubCat'
    }),
    searchName(e) {
      const result = this.products.filter(p => {
        if (p.product) {
          return p.product.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
        }
      })
      return result
    },

    searchID(e) {
      const result = this.products.filter(p => {
        if (p.productId) {
          return p.productId.toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
        }
      })
      return result
    },
    search: _.debounce(function (e) {
      this.searchInput = e.target.value
      this.setFilterCat(null)
      this.setFilterSubCat(null)
      this.setFilterSubSubCat(null)
      this.setSearchName(e.target.value)
      const arrNames = this.searchName(e)
      const arrIDs = this.searchID(e)
      const arr = _.uniqBy([...arrNames, ...arrIDs], 'productId')
      this.changeProducts(arr)
    }, 500),

    toggle() {
      this.isClicked = !this.isClicked
      this.overlay = !this.overlay
    },
    overlayClick() {
      if (this.isClicked) {
        this.isClicked = false
        this.overlay = !this.overlay
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
