import {productsCollection} from '@/firebase'
import {getDocs, setDoc, getDoc, doc, query, where, startAfter, limit} from 'firebase/firestore'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    products: []
  },
  mutations: {
    set(state, products) {
      state.products = products
    }
  },
  actions: {
    async fetch({commit, state}) {
      let filters = []
      if (state.filterCat) {
        filters.push(where('category', '==', state.filterCat))
      }
      if (state.filterSubCat) {
        filters.push(where('subCategory', '==', state.filterSubCat))
      }
      if (state.filterSubSubCat) {
        filters.push(where('subSubCategory', '==', state.filterSubSubCat))
      }
      if (state.filterParam) {
        filters.push(where('filters', 'array-contains', state.filterParam))
      }

      const q = query(productsCollection, ...filters, limit(25))
      const productsDocs = await getDocs(q)
      // console.log(productsDocs)
      const productsList = productsDocs.docs.map(doc => doc.data())
      // console.log(productsList)
      // commit('set', productsList)
      //================================================================
      // const first = query(collection(db, 'cities'), limit(25))
      // const documentSnapshots = await getDocs(first)

      // Get the last visible document
      // const lastVisible = productsDocs.docs[productsDocs.docs.length - 1]
      // console.log('last', lastVisible)

      // Construct a new query starting at this document,
      // get the next 25 cities.
      // const next = query(collection(db, 'cities'), orderBy('population'), startAfter(lastVisible), limit(25))
    }
  }
}
