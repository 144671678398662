import {initializeApp} from 'firebase/app'
import {getFirestore, collection} from 'firebase/firestore'
import {getAuth, onAuthStateChanged} from 'firebase/auth'
import {getStorage} from 'firebase/storage'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_apiKey,
  authDomain: process.env.VUE_APP_authDomain,
  databaseURL: process.env.VUE_APP_databaseURL,
  projectId: process.env.VUE_APP_projectId,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
  appId: process.env.VUE_APP_appId
}
const app = initializeApp(firebaseConfig)

const auth = getAuth()
const getUserInfo = () => new Promise((resolve, reject) => onAuthStateChanged(auth, resolve, reject))

const db = getFirestore(app)
const productsCollection = collection(db, 'products')
const constantsCollection = collection(db, 'constants')

const storage = getStorage(app)

export {auth, db, productsCollection, constantsCollection, getUserInfo, storage}
