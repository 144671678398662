import Vue from 'vue'
import VueRouter from 'vue-router'
import {getUserInfo} from '@/firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {layout: 'customer'},
    component: () => import('@/views/Customer/Home')
  },
  {
    path: '/about',
    name: 'About',
    meta: {layout: 'customer'},
    component: () => import('@/views/Customer/About')
  },
  {
    path: '/products',
    name: 'Products',
    meta: {layout: 'customer'},
    component: () => import('@/views/Customer/Products')
  },
  {
    path: '/products/:id',
    name: 'SingleProducts',
    meta: {layout: 'customer'},
    component: () => import('@/views/Customer/SingleProduct'),
    props: true
  },
  {
    path: '/contacts',
    name: 'Contacts',
    meta: {layout: 'customer'},
    component: () => import('@/views/Customer/Contacts')
  },
  //===== Admin ======//
  {
    path: '/login',
    name: 'Login',
    meta: {layout: 'empty'},
    component: () => import('@/views/Login')
  },
  {
    path: '/admin',
    name: 'AdminPage',
    redirect: '/admin/products',
    meta: {layout: 'admin', auth: true},
    component: () => import('@/views/Admin')
  },
  {
    path: '/admin/products',
    name: 'AdminProducts',
    meta: {layout: 'admin', auth: true},
    component: () => import('@/views/Admin/Products')
  },
  {
    path: '/admin/product',
    name: 'AdminProductCreate',
    meta: {layout: 'admin', auth: true},
    component: () => import('@/views/Admin/Create')
  },
  // {
  //   path: '/admin/product/:id',
  //   name: 'AdminProduct',
  //   meta: {layout: 'admin', auth: true},
  //   component: () => import('@/views/Admin/Product')
  // },
  {
    path: '/admin/product/:id/edit',
    name: 'AdminProductEdit',
    meta: {layout: 'admin', auth: true},
    component: () => import('@/views/Admin/Create')
  },
  {
    path: '/admin/categoryEdit',
    name: 'CategoryEdit',
    meta: {layout: 'admin', auth: true},
    component: () => import('@/views/Admin/CategoryEdit')
  },
  //===== 404 ======//
  {
    path: '/404',
    component: () => import('@/views/NotFound')
  },
  {
    path: '/:notFound(.*)',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

router.beforeEach(async (to, from, next) => {
  const currentUser = await getUserInfo()
  const requireAuth = to.matched.some(record => record.meta.auth)
  if (requireAuth && !currentUser) {
    next('/login')
  } else {
    next()
  }
})
