import {auth} from '@/firebase'
import {signInWithEmailAndPassword} from 'firebase/auth'

export default {
  namespaced: true,
  state: {
    user: {}
  },
  mutations: {
    set(state, user) {
      state.user = user
    },
    setAdmin(state) {
      state.user.isAdmin = true
    }
  },
  actions: {
    async signIn({commit}, {email, password}) {
      try {
        await signInWithEmailAndPassword(auth, email, password)
          .then(userCredential => {
            commit('set', userCredential.user)
            return userCredential.user.getIdTokenResult()
          })
          .then(tokenResult => {
            const isAdmin = tokenResult.claims.isAdmin
            if (isAdmin) commit('setAdmin')
          })
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async setUser({commit}) {
      const user = auth.currentUser
      if (!user) return
      commit('set', user)
      const token = await auth.currentUser.getIdTokenResult()
      if (token.claims.isAdmin) commit('setAdmin')
    },
    async signOut({commit}) {
      try {
        await auth.signOut()
        commit('set', {})
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  }
}
