import {productsCollection, storage} from '@/firebase'
import {getDocs, setDoc, getDoc, doc, query, where, deleteDoc, orderBy} from 'firebase/firestore'
import {ref, uploadBytes, getDownloadURL, deleteObject} from 'firebase/storage'
import pagination from './pagination'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    products: [],
    filteredProducts: [],
    searchName: '',
    product: {},
    //FILTERS
    filterCat: null,
    filterSubCat: null,
    filterSubSubCat: null,
    filterParam: null,
    filterSearch: null
  },
  mutations: {
    set(state, products) {
      state.products = products
    },
    setProduct(state, product) {
      state.product = product
    },
    setFilterCat(state, filterCat) {
      state.filterCat = filterCat
    },
    setFilterSubCat(state, filterSubCat) {
      state.filterSubCat = filterSubCat
    },
    setFilterSubSubCat(state, filterSubSubCat) {
      state.filterSubSubCat = filterSubSubCat
    },
    setFilterParam(state, filterParam) {
      state.filterParam = filterParam
    },
    changeProducts(state, products) {
      state.filteredProducts = products
    },
    setSearchName(state, name) {
      state.searchName = name
    }
  },
  actions: {
    async fetch({commit, state}, id) {
      let filters = []
      if (state.filterCat) {
        filters.push(where('category', '==', state.filterCat))
      }
      if (state.filterSubCat) {
        filters.push(where('subCategory', '==', state.filterSubCat))
      }
      if (state.filterSubSubCat) {
        filters.push(where('subSubCategory', '==', state.filterSubSubCat))
      }
      if (state.filterParam) {
        filters.push(where('filters', 'array-contains', state.filterParam))
      }
      if (id) {
        filters.push(where('productId', '==', id))
      }

      const q = query(productsCollection, ...filters)
      const productsDocs = await getDocs(q)
      const productsList = productsDocs.docs.map(doc => doc.data())
      commit('set', productsList)
    },
    async fetchProduct({commit}, id) {
      const productDoc = await getDoc(doc(productsCollection, id))
      commit('setProduct', productDoc.data())
    },
    async create({commit}, product) {
      try {
        await setDoc(doc(productsCollection, product.productId), product, {merge: true})
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async addPhoto({commit, dispatch}, {filesList, id, urlsList}) {
      const photos = []
      if (urlsList) photos.push(...urlsList)
      try {
        for (const file of filesList) {
          const storageRef = ref(storage, 'imgs/' + id + '/' + file.name)
          const uploadTask = await uploadBytes(storageRef, file)
          const downloadURL = await getDownloadURL(uploadTask.ref)
          photos.push({url: downloadURL, ref: uploadTask.metadata.fullPath, id: photos.length})
        }
        await dispatch('setPhotosList', {filesList: photos, id})
        await dispatch('fetch')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async delPhotos({commit, dispatch}, {files, filesList, id}) {
      try {
        for (const file of files) {
          const storageRef = ref(storage, file.ref)
          await deleteObject(storageRef)
        }
        await dispatch('setPhotosList', {filesList, id})
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async setPhotosList({commit}, {filesList, id}) {
      try {
        await setDoc(doc(productsCollection, id), {photos: filesList}, {merge: true})
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async delProduct({commit, dispatch}, id) {
      try {
        await deleteDoc(doc(productsCollection, id))
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  },
  modules: {
    pagination
  }
}
