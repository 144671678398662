import {constantsCollection} from '@/firebase'
import {getDocs, setDoc, doc} from 'firebase/firestore'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    categories: [],
    localCategories: []
  },
  mutations: {
    setCategories(state, cats) {
      state.categories = cats
    },
    setLocalCategories(state, cats) {
      state.localCategories = cats
    },
    addCategory(state, catParam) {
      const {cat, subCat, subSubCat} = catParam
      if (subSubCat) {
        state.categories
          .find(c => c.id === cat.id)
          .items.find(s => s.id === subCat.id)
          .items.push(_.cloneDeep(subSubCat))
      } else if (subCat && !subSubCat) {
        state.categories.find(c => c.id === cat.id).items.push(_.cloneDeep(subCat))
      } else {
        state.categories.push(_.cloneDeep(cat))
      }
    },
    addLocalCategory(state, catParam) {
      const {cat, subCat, subSubCat} = catParam
      if (subSubCat) {
        state.localCategories
          .find(c => c.id === cat.id)
          .items.find(s => s.id === subCat.id)
          .items.push(_.cloneDeep(subSubCat))
      } else if (subCat && !subSubCat) {
        const editCat = state.localCategories.find(c => c.id === cat.id)
        editCat.items.push(_.cloneDeep(subCat))
      } else {
        state.localCategories.push(_.cloneDeep(cat))
      }
    },
    delCategory(state, catParam) {
      const {cat, subCat, subSubCat} = catParam
      if (subSubCat) {
        const filterCats = state.categories.find(c => c.name === cat).items
        const filterSubCats = filterCats.find(c => c.name === subCat).items
        filterSubCats.splice(filterSubCats.indexOf(subSubCat), 1)
      } else if (subCat && !subSubCat) {
        const filterCats = state.categories.find(c => c.name === cat).items
        filterCats.splice(filterCats.indexOf(subCat), 1)
      } else {
        state.categories = state.categories.filter(c => c.name !== cat)
      }
    },
    delLocalCategory(state, catParam) {
      const {cat, subCat, subSubCat} = catParam
      if (subSubCat) {
        const filterCats = state.localCategories.find(c => c.name === cat).items
        const filterSubCats = filterCats.find(c => c.name === subCat).items
        filterSubCats.splice(filterSubCats.indexOf(subSubCat), 1)
      } else if (subCat && !subSubCat) {
        const filterCats = state.localCategories.find(c => c.name === cat).items
        filterCats.splice(filterCats.indexOf(subCat), 1)
      } else {
        state.localCategories = state.localCategories.filter(c => c.name !== cat)
      }
    }
  },
  actions: {
    async addCategory({commit, state}, {cat, subCat, subSubCat}) {
      try {
        commit('addLocalCategory', {cat, subCat, subSubCat})
        await setDoc(doc(constantsCollection, 'categoriesList'), {categories: state.localCategories})
        commit('addCategory', {cat, subCat, subSubCat})
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async delCategory({commit, state, rootState}) {
      try {
        const {filterCat: cat, filterSubCat: subCat, filterSubSubCat: subSubCat} = rootState.admin.products
        commit('delLocalCategory', {cat, subCat, subSubCat})
        await setDoc(doc(constantsCollection, 'categoriesList'), {categories: state.localCategories})
        commit('delCategory', {cat, subCat, subSubCat})
        commit('admin/products/setFilterCat', null, {root: true})
        commit('admin/products/setFilterSubCat', null, {root: true})
        commit('admin/products/setFilterSubSubCat', null, {root: true})
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  }
}
