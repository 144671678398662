<template>
  <div>
    <div :class="['overlay', overlay ? 'overlayActive' : '']" @click="overlayClick"></div>
    <nav class="nav-menu">
      <div id="showblock" class="line-wrap" @click="toggle">
        <div class="" :class="['line', isClicked ? 'lineClicked' : '']"></div>
      </div>

      <ul id="menu" class="menu" :class="{clicked: isClicked}">
        <li class="menu__item">
          <router-link to="/" exact class="menu__link" @click.native="toggle">Home</router-link>
        </li>
        <li class="menu__item">
          <router-link to="/about" class="menu__link" @click.native="toggle">About</router-link>
        </li>
        <li class="menu__item">
          <router-link to="/products" class="menu__link" @click.native="toggle">Products</router-link>
        </li>
        <li class="menu__item">
          <router-link to="/contacts" class="menu__link" @click.native="toggle">Contact</router-link>
        </li>
        <li class="menu__item">
          <router-link to="/contacts" class="menu__link menu__link--mobile" @click.native="toggle">
            Get in Touch
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isClicked: false,
      overlay: false
    }
  },

  methods: {
    toggle() {
      this.isClicked = !this.isClicked
      this.overlay = !this.overlay
    },
    overlayClick() {
      if (this.isClicked) {
        this.isClicked = false
        this.overlay = !this.overlay
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
