<template>
  <div class="customer-layout">
    <TheHeader />
    <router-view />
    <TheFooter />
  </div>
</template>

<script>
import TheHeader from '@/views/Customer/components/TheHeader'
import TheFooter from '@/views/Customer/components/TheFooter'
import {mapActions} from 'vuex'
export default {
  name: 'CustomerLayout',
  components: {
    TheHeader,
    TheFooter
  },
  async mounted() {
    await this.fetch()
  },
  methods: {
    ...mapActions({
      fetch: 'main/fetchConstants'
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/layouts';
</style>
