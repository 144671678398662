import {constantsCollection} from '@/firebase'
import {getDocs, setDoc, doc} from 'firebase/firestore'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    constants: [],
    categories: [],
    localCategories: []
  },
  mutations: {
    set(state, constants) {
      state.constants = constants
    }
  },
  actions: {
    async fetchConstants({commit}) {
      const constantsDocs = await getDocs(constantsCollection)
      const constantsList = constantsDocs.docs.map(doc => doc.data())
      commit('set', constantsList)
      commit('categories/setCategories', constantsList[0]?.categories || [], {root: true})
      commit('categories/setLocalCategories', _.cloneDeep(constantsList[0]?.categories) || [], {root: true})
    },
    async changeCategory({commit}, cats) {
      try {
        await setDoc(doc(constantsCollection, 'categoriesList'), {categories: cats})
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  },
  getters: {
    categories: s => s.constants[0]?.categories || []
  }
}
