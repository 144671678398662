<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import CustomerLayout from '@/layouts/customerLayout'
import EmptyLayout from '@/layouts/emptyLayout'
import AdminLayout from '@/layouts/adminLayout'

export default {
  name: 'Home',
  components: {
    EmptyLayout,
    CustomerLayout,
    AdminLayout
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout'
    }
  },
  created() {
    this.$modal.open = function () {
      document.body.style.top = `-${window.scrollY}px`
      document.body.style.position = 'fixed'
      document.body.style.width = '100%'
    }
    this.$modal.close = function () {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }

    this.$modal.display = function (component, component_properties, modal_properties, modal_events) {
      this.$modal.show(
        component,
        component_properties,
        {...this.$modal.styles, ...modal_properties},
        {'before-open': this.$modal.open, 'before-close': this.$modal.close, ...modal_events}
      )
    }.bind(this)
    this.$modal.styles = {
      classes: 'modal',
      adaptive: true
    }
  },
  metaInfo: {
    title: 'Flesherton Welding Supply',
    titleTemplate: '%s | ESAB Dealer',
    htmlAttrs: {
      lang: 'en-CA'
    },
    meta: [
      {
        name: 'description',
        content:
          'Flesherton Welding Supply is your local ESAB dealer for Grey Highlands, Southgate, and Shelburne area. We stock a variety of equipment and welding supplies.'
      }
    ]
  }
}
</script>
<style lang="scss">
@import '@/assets/styles/style.scss';
</style>
