import Vue from 'vue'
import Vuex from 'vuex'

import products from './products/products'
import admin from './admin'
import main from './main'
import categories from './categories'
import auth from './auth/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    products,
    main,
    auth,
    admin,
    categories
  }
})
